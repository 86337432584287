import React from 'react';
import {Link} from 'react-router-dom'
import { InstagramEmbed } from 'react-social-media-embed';
import logo from '../../images/logo/logo.png'
import in1 from '../../images/instragram/1.jpg'
import in2 from '../../images/instragram/2.jpg'
import in3 from '../../images/instragram/3.jpg'
import in4 from '../../images/instragram/4.jpg'
import in5 from '../../images/instragram/5.jpg'
import in6 from '../../images/instragram/6.jpg'
import './style.css'

const FooterSection = () => {

    return(
        <div className="wpo-footer-area">
            {/* <div className="wpo-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 footer-t">
                            <div className="wpo-footer-logo">
                                <img src={logo} alt=""/>
                            </div>
                            <p>There are many variations of Lorem passages of Lorem Ipsum available, but the majority</p>
                            <p>By injected humour, or randomised words</p>
                            <div className="social">
                                <ul className="d-flex">
                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 footer-t">
                            <div className="footer-link">
                                <h3>Quick Link</h3>
                                <ul>
                                    <li><Link to="/">About Us</Link></li>
                                    <li><Link to="/">Service</Link></li>
                                    <li><Link to="/">Location</Link></li>
                                    <li><Link to="/">Testimonial</Link></li>
                                    <li><Link to="/">Contact</Link></li>
                                    <li><Link to="/">Blog</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 footer-b">
                            <div className="Recent-News-area">
                                <h3>Recent News</h3>
                                <div className="resent-sub">
                                    <p>There are many variations of passages of Lorem</p>
                                    <span><i className="fa fa-clock-o" aria-hidden="true"></i> Octobor 10, 2018</span>
                                </div>
                                <p>There are many variations of passages of Lorem</p>
                                <span><i className="fa fa-clock-o" aria-hidden="true"></i> Octobor 10, 2018</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="footer-widget instagram">
                                <h3>Instagram</h3>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <InstagramEmbed url="https://www.instagram.com/p/CUbHfhpswxt/" width={328} />
                                </div>
                                <ul className="d-flex">
                                    <li><Link to="/"><img src={in1} alt=""/></Link></li>
                                    <li><Link to="/"><img src={in2} alt=""/></Link></li>
                                    <li><Link to="/"><img src={in3} alt=""/></Link></li>
                                    <li><Link to="/"><img src={in4} alt=""/></Link></li>
                                    <li><Link to="/"><img src={in5} alt=""/></Link></li>
                                    <li><Link to="/"><img src={in6} alt=""/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="wpo-footer-bottom">
                <div className="container">
                    <div className="wpo-footer-bottom-content">
                        <div className="row">
                        <div className="social col-6">
                                <ul className="d-flex">
                                    <li><a href="https://www.facebook.com/profile.php?id=61558193304864" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.instagram.com/kinexion.mx/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li><a href="https://wa.me/523329226745" target="_blank"><i className="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-6" style={{display: 'flex', justifyContent:'end', alignItems:'center'}}>
                                <span>© Copyrights 2024. All Rights Reserved.</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
     )

}

export default FooterSection;
