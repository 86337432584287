import React from  'react';

// components
import Navbar from '../../components/Navbar'
import SimpleSlider from '../../components/hero'
import Features2 from '../../components/features2'
import Features from '../../components/features'
import AboutSection from '../../components/about'
import About2Section from '../../components/about2'
import ServiceArea from '../../components/ServiceArea'
import ServiceArea2 from '../../components/ServiceArea2'
import TruckArea from '../../components/Truck'
import WpoFeatures from '../../components/wpofeatures'
import CounterSection from '../../components/CounterSection'
import PricingSection from '../../components/Pricing'
import Testimonial from '../../components/testimonial'
import TeamSection from '../../components/Team'
import Routes from '../../components/Routes'
import BlogSection from '../../components/BlogSection'
import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
import Contactpage from '../../components/Contactpage'


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider/>
           <About2Section/>
           <Routes/>
           <ServiceArea2/>
           {/* <Features/> */}
           {/* <AboutSection/> */}
           <Features2/>
           <ServiceArea/>
           {/* <TruckArea/> */}
           {/* <WpoFeatures/> */}
           {/* <CounterSection/> */}
           {/* <PricingSection/> */}
           {/* <Testimonial/> */}
           {/* <Routes/> */}
           <Contactpage/>
           {/* <Features2/> */}
           {/* <BlogSection/> */}
           {/* <Newsletter/> */}
           <FooterSection/>
       </div>
    )
}

export default Homepage;
